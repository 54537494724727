<template>
  <img src="/hino9_logo2.png" alt="Hino9 Logo" :style="computedStyle">
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, onUnmounted } from 'vue'

const windowWidth = ref(window.innerWidth)
const windowHeight = ref(window.innerHeight)

const updateWindowDimensions = () => {
  windowWidth.value = window.innerWidth
  windowHeight.value = window.innerHeight
}

onMounted(() => {
  window.addEventListener('resize', updateWindowDimensions)
})

onUnmounted(() => {
  window.removeEventListener('resize', updateWindowDimensions)
})

const computedStyle = computed(() => {
  const isPortrait = windowHeight.value > windowWidth.value
  const isSmallScreen = windowWidth.value < 600 // Adjust this breakpoint as needed

  if (isPortrait && isSmallScreen) {
    const calculatedWidth = Math.floor(windowWidth.value * 0.8)
    return {
      width: `${calculatedWidth}px`,
      height: 'auto'
    }
  } else {
    const calculatedHeight = Math.floor(windowHeight.value * 0.6)
    return {
      width: 'auto',
      height: `${calculatedHeight}px`
    }
  }
})
</script>