<template>
  <VaLayout class="h-screen lg_container1">
    <template class="h-full flex flex-col items-center justify-center">
      <template :class="computedClass">
        <CompanyLogo />
      </template>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import CompanyLogo from '@/components/CompanyLogo.vue'
const computedStyle = computed(() => {
  return {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000'
  }
})
const computedClass = computed(() => {
  return {
    'h-full': true,
    'flex': true,
    'flex-col': true,
    'items-center': true,
    'justify-center': true
  }
})
</script>
<style scoped>
.h-full {
  background-color: #000;
  width: 100vw;
  height: 100vh;
}
</style>
